import React from "react";
import ReactLoading from "react-loading";
import { Content } from "./styles";
import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

interface ModalProps {
    open: boolean;
    close: () => void;
}

const Loading: React.FC<ModalProps> = ({ open, close }) => (

    <CModal
        show={open}
        fade
        onClose={close}
        size="xl"
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            boxShadow: "none",
            height: "100vh"
        }}
    >
        <Content>
            <ReactLoading type="bubbles" color="#FFF" width={50} height={50} />
        </Content>
    </CModal>
);

export default Loading;